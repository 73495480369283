import React, { useEffect, useRef } from 'react';
import { styled } from "@mui/material";
import Matter from 'matter-js';
import pineImage from "../resources/PineSprite.png";
import { isMobile } from 'react-device-detect';

const Box = styled("div")(({theme}) => ({
    width: isMobile ? "75vw" : "38.5vw",
    height: "75vh",
    top: 0,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    "&:nth-of-type(2)": {
        position: "absolute",
        zIndex: 5
    },
}))

export default function BoundingBox(props) {
    const boxRef = useRef(null);
    const { pineCount } = props;

    useEffect(() => {

        const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

        const refWidth = isMobile ? viewportWidth * (75 / 100) : viewportWidth * (38.5 / 100)
        const refHeight = viewportHeight * (75 / 100);

        const matterData = isMobile ? {
            sprite: {
                x: {
                    count: 10,
                    space: 60,
                    position: 60
                },
                y: 36,
                size: 17,
                scale: 0.085
            },
            yHood: refHeight / 2 - 60
        } : {
            sprite: {
                x: {
                    count: 5,
                    space: 30,
                    position: refWidth / 2.5
                },
                y: 300,
                size: 30,
                scale: 0.15
            },
            yHood: 40
        };

        const { Runner, Engine, Render, World, Bodies } = Matter;
        const { sprite, yHood } = matterData;

        const engine = Engine.create( );

        const render = Render.create({
            element: boxRef.current,
            engine: engine,
            options: {
                width: refWidth,
                height: refHeight,
                wireframes: false,
                background: "transparent"
            }
        });

        const ball = Array(Math.round(pineCount * (isMobile ? 1.5 : 1))).fill().map((_, index) => {

            const x = (index % sprite.x.count) * (sprite.x.space) + sprite.x.position;
            const y = refHeight - sprite.y;

            return Bodies.circle(x, y, sprite.size, {
                restitution: 0.3,
                friction: 0.2,
                render: {
                    sprite: {
                        texture: pineImage,
                        xScale: sprite.scale,
                        yScale: sprite.scale
                    }
                },
            });
        })


        const objects = [
            Bodies.rectangle(refWidth*0.5, refHeight, refWidth*0.8, 5, { isStatic: true, render: {opacity: 0}}),
            Bodies.rectangle(refWidth/2, yHood, 1, refHeight*2, { isStatic: true, render: {opacity: 0}}),
            Bodies.rectangle(refWidth/2, yHood, 1, refHeight*2, { isStatic: true, render: {opacity: 0}}),
            Bodies.rectangle(refWidth*0.1, refHeight, 1, refWidth*0.275, { isStatic: true, render: {opacity: 0}}),
            Bodies.rectangle(refWidth-refWidth*0.1, refHeight, 1, refWidth*0.275, { isStatic: true, render: {opacity: 0}}),
        ]

        Matter.Body.setAngle(objects[1], 0.55)
        Matter.Body.setAngle(objects[2], -0.55)

        Matter.Body.setAngle(objects[3], -0.825)
        Matter.Body.setAngle(objects[4], 0.825)

        World.add(engine.world, [...objects, ...ball]);

        Runner.run(engine);
        Render.run(render);

        setTimeout(() => {
            engine.world.gravity.y = 0;
        }, 1500)

        return () => {
            Render.stop(render);
            World.clear(engine.world);
            Engine.clear(engine);

            if (render.canvas) {
                render.canvas.remove();
                render.canvas = null;
            }

            render.context = null;
            render.textures = {};
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box ref={boxRef} />
    );
}