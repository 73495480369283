import React, {useEffect} from 'react';
import {Box, styled, Grid, Typography} from "@mui/material";
import plaque from "../resources/Plaque.png";
import {isMobile} from "react-device-detect";
import NameLetter from "../list/NameLetter";
import pattern from "../resources/GoldMaterial.png";

const PlaqueImg = styled("img")(({theme}) => ({
    height: "100%",
    width: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: -1
}));

const PlaqueBox = styled(Box)(({theme}) => ({
    position: "absolute",
    bottom: "20vh",
    left: "50%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down('md')]: {
        width: "35vw",
        height: "15vw"
    },
    [theme.breakpoints.up('md')]: {
        width: "15vw",
        height: "10vh"
    },
}))

const ListTypography = styled(Typography)(({theme}) => ({
    fontSize: isMobile ? "1.75rem" : "3rem",
    fontWeight: "bold",
    fontFamily: "Antonio",
    background: `url(${pattern})`,
    backgroundSize: "cover",
    color: "transparent",
    backgroundClip: "text",
    webkitTextFillColor: "transparent"
}))

const PlaqueGrid = styled(Grid)(({theme}) => ({
    fontWeight: "bold",
    color: "#FFD400",
    fontFamily: "Antonio",
    position: "absolute",
    width: "fit-content",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -55%)"
}));

function Plaque(props) {
    const { points } = props;

    return (
        <PlaqueBox>
            <PlaqueGrid container>
                {Object.entries(points.toString().split("")).map(([key, l]) => (
                    <Grid item key={`number-#${key}`}>
                        <ListTypography sx={{zIndex: 2, position: "relative"}}>{l}</ListTypography>
                    </Grid>
                ))}
            </PlaqueGrid>
            <PlaqueImg src={plaque} alt={"Points plaque"} />
        </PlaqueBox>
    );
}

export default Plaque;