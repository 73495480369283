import Basket from "./basket/Basket";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NotFound from "./notfound/NotFound";
import Admin from "./admin/Admin";

const router = createBrowserRouter([
  {
    path: "*",
    element: <NotFound />
  },
  {
    path: "/:unit/:team",
    element: <Basket />,
  },
  {
    path: "/:unit/admin/console",
    element: <Admin />
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
