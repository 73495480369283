import React, {useState} from 'react';
import {Box, styled} from "@mui/material";
import ScoutImage from "../resources/scout.png";
import NameLetter from "../list/NameLetter";
import {isMobile} from "react-device-detect";
import listPlaque from "../resources/ListPlaque.png";


const Image = styled("img")(({theme}) => ({
    position: "absolute",
    bottom: "10vh",
    right: "28vw",
    [theme.breakpoints.down('md')]: {
        height: "50vw"
    },
    [theme.breakpoints.up('md')]: {
        height: "30vh"
    },
}));

const PositionBox = styled(Box)(({theme}) => ({
    position: "absolute",
    top: "25%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: `url(${listPlaque})`,
    height: "20vh",
    width: "40vw",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
}));

export default function NotFound() {
    return (
        <Box>
            <PositionBox>
                <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    {Object.entries("Niestety nie znalazłem takiej strony".split("")).map(([key, l]) => <NameLetter sx={{width: "fit-content", "&>*": {fontSize: isMobile ? "1rem" : "1.5rem"}}} key={`letter-#${key}`}>{key === "0" ? l.toUpperCase() : l}</NameLetter>)}
                </Box>
                <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    {Object.entries("Sprawdz jeszcze raz adres".split("")).map(([key, l]) => <NameLetter sx={{width: "fit-content", "&>*": {fontSize: isMobile ? "1rem" : "1.5rem"}}} key={`letter-#${key}`}>{key === "0" ? l.toUpperCase() : l}</NameLetter>)}
                </Box>
            </PositionBox>
            <Image src={ScoutImage} alt={"Scout"} />
        </Box>
    );
}
