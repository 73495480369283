import React from 'react';
import NameLetter from "./NameLetter";
import {Grid, styled} from "@mui/material";

const NameGrid = styled(Grid)(({theme}) => ({
    width: "fit-content",
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -55%)"
}));

function TeamName(props) {
    const { name } = props;

    return (
        <NameGrid container>
            {Object.entries(name.split("")).map(([key, l]) => <NameLetter key={`letter-#${key}`}>{l}</NameLetter>)}
        </NameGrid>
    );
}

export default TeamName;