import React, { useState, useEffect } from 'react';
import { Button, TextField, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import axios from 'axios';
import {useParams} from "react-router-dom";

function Admin() {
    const [groups, setGroups] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [points, setPoints] = useState('');
    const { unit } = useParams();

    useEffect(() => {
        fetchGroups();
    }, []);

    const fetchGroups = async () => {
        try {
            const response = await axios.get(`https://queivan.com:46759/units/${unit}`);
            console.log(response.data)
            setGroups(response.data);
        } catch (error) {
            console.error("Error fetching groups", error);
        }
    };

    const handleAddGroup = async () => {
        const unitName = prompt("Podaj nazwę zastępu:");
        if (unitName) {
            try {
                await axios.post('https://queivan.com:46759/units', { name: unit, unitName: unitName });
                fetchGroups();
            } catch (error) {
                console.error("Error adding group", error);
            }
        }
    };

    const handleDeleteGroup = async (unitName) => {
        try {
            await axios.delete(`https://queivan.com:46759/units/${unit}/${unitName}`);
            fetchGroups();
        } catch (error) {
            console.error("Error deleting group", error);
        }
    };

    const handleClickOpen = (group) => {
        setSelectedGroup(group);
        setPoints(group.points);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEditPoints = async () => {
        try {
            await axios.put(`https://queivan.com:46759/points/${unit}/${selectedGroup}`, { points: parseInt(points, 10) });
            fetchGroups();
            handleClose();
        } catch (error) {
            console.error("Error updating points", error);
        }
    };

    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleAddGroup}>
                Add Group
            </Button>
            <List>
                {groups.map((group, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={group.unit_name.charAt(0).toUpperCase() + group.unit_name.slice(1)} style={{color: "#fff"}} secondary={`Points: ${group.points}`} />
                        <Button onClick={() => handleClickOpen(group.unit_name)}>Edit Points</Button>
                        <Button onClick={() => handleDeleteGroup(group.unit_name)}>Delete</Button>
                    </ListItem>
                ))}
            </List>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Group Points</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Points"
                        type="number"
                        fullWidth
                        value={points}
                        onChange={(e) => setPoints(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleEditPoints} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Admin;
