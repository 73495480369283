import React, {useEffect, useState} from 'react';
import wood from '../resources/log/Log.png';
import {Box, Collapse, Grid, styled} from "@mui/material";
import TeamName from "./TeamName";
import triangle from "../resources/log/Triangle.png";
import listPlaque from "../resources/ListPlaque.png";
import NameLetter from "./NameLetter";
import { isMobile } from 'react-device-detect';
import axios from "axios";
import {useNavigate} from "react-router-dom";

const ListBox = styled(Box)(({theme}) => ({
    position: "relative",
    width: "fit-content"
}))

const ListImg = styled("img")(({theme}) => ({
    width: "100%"
}));

const MoreIcon = styled("img", {
    shouldForwardProp: (props) => props !== 'open',
})(({theme, open}) => ({
    width: "6%",
    position: "absolute",
    right: 15,
    top: "45%",
    transform: open ? "translateY(-55%) rotate(180deg)" : "translateY(-55%)"
}))

function TeamList(props) {
    const [listOpen, setListOpen] = useState(false);
    const [groups, setGroups] = useState([]);
    const navigate = useNavigate();
    const { name, unit } = props;

    const handleListClick = () => {
        if(groups.length > 1)
            setListOpen(!listOpen);
    }

    const navigateTo = (group) => {
        group = Object.entries(group).map(([key, l]) => key === "0" ? l.toUpperCase() : l).reduce((a, b) => a+b);
        window.location.href = `/${unit}/${group}`;
    }

    useEffect(() => {
        axios.get(`https://queivan.com:46759/units/${unit}`)
            .then((res) => res.data)
            .then((data) => setGroups(data.map((el) => el.unit_name)));
    }, []);

    return (
        <Box sx={{position: "absolute", top: 20, right: 15, width: isMobile ? "55vw" : "20vw"}}>
            <ListBox onClick={handleListClick}>
                <ListImg src={wood}/>
                <TeamName name={Object.entries(name).map(([key, l]) => key === "0" ? l.toUpperCase() : l).reduce((a, b) => a+b)}/>
                {groups.length > 1 && (<MoreIcon src={triangle} open={listOpen} />)}
            </ListBox>
            <Collapse in={listOpen} sx={{marginTop: "-30px"}}>
                <Box sx={{display: "flex", justifyContent: "center"}}>
                    <Grid container sx={{maxWidth: "calc(100% - 10px)", overflow: "hidden", maxHeight: "20vh", marginBottom: "32px", minHeight: "10vh", background: `url(${listPlaque})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%"}}>
                        <Box sx={{height: "calc(100% - 16px)", width: "calc(100% - 25px)", overflow: "auto"}}>
                            {groups.length > 1 && Object.entries(groups).filter((el) => el[1] !== name.toLowerCase()).map(([key, group]) => (
                                <Grid onClick={() => navigateTo(group)} item key={`group-#${key}`} sx={{paddingTop: "16px", paddingLeft: isMobile ? "32px" : "48px", width: "100%", "&:last-of-type": {paddingBottom: "8px"}, "&:first-of-type": {paddingTop: "32px"}}}>
                                    <Grid container sx={{display: "flex"}}>
                                        {Object.entries(group.split("")).map(([key, l]) => <NameLetter sx={{width: "fit-content", "&>*": {fontSize: isMobile ? "1rem" : "1.25rem"}}} key={`letter-#${key}`}>{key === "0" ? l.toUpperCase() : l}</NameLetter>)}
                                    </Grid>
                                </Grid>
                            ))}
                        </Box>
                    </Grid>
                </Box>
            </Collapse>
        </Box>
    );
}

export default TeamList;