import React, {useEffect, useState} from 'react';
import fullLogo from '../resources/logo/LogoFull.png';
import basket from "../resources/basket/BasketBack.png";
import {Box, styled} from "@mui/material";
import bottom from "../resources/basket/BasketFront.png";
import BoundingBox from "./BoundingBox";
import Plaque from "./Plaque";
import TeamList from "../list/TeamList";
import {useParams} from "react-router-dom";
import axios from "axios";
import listPlaque from "../resources/ListPlaque.png";

const StyledLogo = styled("img")(({theme}) => ({
    position: "absolute",
    height: "auto",
    left: 15,
    [theme.breakpoints.down('md')]: {
        width: "15vw",
        opacity: .75,
        top: 15
    },
    [theme.breakpoints.up('md')]: {
        width: "10vw",
        opacity: .25,
        bottom: 15
    },
}));

const BasketImage = styled("img")(({theme}) => ({
    position: "absolute",
    bottom: "15vh",
    left: "50%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down('md')]: {
        width: "90vw",
        height: "50vw"
    },
    [theme.breakpoints.up('md')]: {
        width: "45vw",
        height: "30vh"
    },
}));

export default function Basket() {
    const [count, setCount] = useState(null);
    const {unit, team} = useParams();

    useEffect(() => {
        if(team !== '')
            axios.get(`https://queivan.com:46759/points/${unit}/${team}`)
                .then((res) => res.data)
                .then((data) =>
                    setCount(data[0].points)
                );
    }, []);

    return (
        <Box>
            <StyledLogo src={fullLogo} alt={"logotyp"}/>
            <BasketImage src={basket} alt={"Points basket"}/>
            {count !== null && <BoundingBox pineCount={count} />}
            <BasketImage src={bottom} alt={"Points basket bottom"}/>
            {count !== null && (<Plaque points={count} />)}
            <TeamList unit={unit} name={team} />
        </Box>
    );
}
