import React, {useEffect, useState} from 'react';
import { Grid, styled } from "@mui/material";
import pattern from "../resources/log/WoodPattern.png";

const MainTypography = styled("span")(({theme}) => ({
    fontSize: "1.25rem",
    fontWeight: "bold",
    fontFamily: "Antonio",
}))

const ListTypography = styled(MainTypography)(({theme}) => ({
    background: `url(${pattern})`,
    backgroundSize: "cover",
    color: "transparent",
    backgroundClip: "text",
    webkitTextFillColor: "transparent",
    textShadow: "0 0 20px #000",
}));

function NameLetter(props) {
    const { children } = props;
    const [letter, setLetter] = useState(children);

    useEffect(() => {
        setLetter((prev) => {
            if (prev === " ") return "\u00A0";
            else return prev;
        });
    }, []);

    return (
        <Grid item sx={{...props.sx}}>
            <ListTypography>{letter}</ListTypography>
        </Grid>
    );
}

export default NameLetter;